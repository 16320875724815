import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { InProClassRosterTableData } from './InProClassRosterTableData';
import { TableDataProvider } from '../../models/TableDataProvider';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';

export class InProClassRosterDataProvider extends TableDataProvider<
  InProClassRosterTableData,
  BaseCandidate,
  SearchCandidateDto
> {
  protected transformer = (t: BaseCandidate): InProClassRosterTableData =>
    new InProClassRosterTableData(t);

  protected queryHandler = async (
    searchDto: SearchCandidateDto
  ): Promise<SearchResponseDto<BaseCandidate>> => {
    const results: SearchResponseDto<BaseCandidate> = await this.store.dispatch(
      'candidateModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}
