import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { InProClassRosterTableData } from './InProClassRosterTableData';

export const InProClassRosterTableConfigBuilder: TableConfigBuilder<
  InProClassRosterTableData,
  BaseCandidate,
  never,
  SearchCandidateDto
> = (): ITableConfiguration<
  InProClassRosterTableData,
  BaseCandidate,
  never
> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: InProClassRosterTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'identificationInformationLastname'
      },
      {
        columnTitle: 'Rank',
        render: 'rank',
        sortKey: 'militaryStatusRanking'
      },
      {
        columnTitle: 'Phone',
        render: 'phone',
        sortKey: 'contactInformationHomephone'
      },
      {
        columnTitle: 'Email',
        render: 'email',
        sortKey: 'contactInformationEmail'
      },
      {
        columnTitle: 'Prior Service',
        render: 'priorService',
        sortKey: 'militaryStatusHaspriorservice'
      },
      {
        columnTitle: 'Prior Aircrew',
        render: 'priorAircrew',
        sortKey: 'priorAirCrewIsprioraircrew'
      },
      {
        columnTitle: 'Badge',
        render: 'badge',
        sortKey: 'militaryStatusBadge'
      },
      {
        columnTitle: 'PPL',
        render: 'ppl',
        sortKey: 'priorAirCrewHasprivatelicense'
      },
      {
        columnTitle: 'DoD ID',
        render: 'dodId',
        sortKey: 'identificationInformationEdipi'
      },
      {
        columnTitle: 'SSN',
        render: 'ssn'
      }
    ]
  };
};
