import { BadgeType } from '@/enums';
import { EMilitaryRank } from '@/enums/ECurrentGrade';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { DisplayNameUtil } from '@/util/displayName';
import { SsnUtils } from '../../Common/util/ssn-utils';
import { ITableData } from '../../models/TableData';

export class InProClassRosterTableData implements ITableData {
  entityId!: number;
  isArchived!: boolean;
  name!: string;
  rank!: EMilitaryRank | null;
  phone!: string;
  email!: string | null;
  priorService!: string;
  priorAircrew!: string;
  ppl!: string;
  badge!: BadgeType | null;
  dodId!: number | null;
  ssn!: string | null;

  constructor(candidate: BaseCandidate) {
    this.entityId = candidate.id;
    this.isArchived = candidate.isArchived;
    this.phone = candidate.formattedHomePhone;
    if (
      candidate.identificationInformationFirstname &&
      candidate.identificationInformationLastname
    ) {
      this.name = DisplayNameUtil.flattenName(
        candidate.identificationInformationFirstname.toUpperCase(),
        candidate.identificationInformationLastname.toUpperCase()
      );
    }
    this.email = candidate.contactInformationEmail;
    this.priorService = candidate.hasPriorService;
    this.priorAircrew = candidate.wasPriorAirCrew;
    this.ppl = candidate.hasPrivatePilotsLicense;
    this.badge = candidate.militaryStatusBadge;
    this.rank = candidate.militaryStatusRanking;
    this.dodId = candidate.identificationInformationEdipi;
    this.ssn = SsnUtils.ConvertToReadableSSN(
      candidate.identificationInformationSocialsecuritynumber
    );
  }
}
