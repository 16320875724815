
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import FetchMixin from '@/mixins/FetchMixin';
import Routes from '@/router/Routes';
import { formatDate, formatTime } from '@/services/formatService';
import { InProClassRosterTableData } from '@/components/Table/InprocessingClass/Roster/InProClassRosterTableData';
import { InProClassRosterDataProvider } from '@/components/Table/InprocessingClass/Roster/InProClassRosterDataProvider';
import { InProClassRosterTableConfigBuilder } from '@/components/Table/InprocessingClass/Roster/InProClassRosterTableConfigBuilder';
import { SearchInProcessingClassDto } from '@/models/Dtos/searchInProcessingClassDto';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
@Component<InProcessingRosterPage>({
  components: {
    ButtonComponent,
    IconComponent,
    PageLayout,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent
  }
})
export default class InProcessingRosterPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;
  fromReports = 'false';
  setLocation(): void {
    if (this.$route.params.fromReports)
      this.fromReports = this.$route.params.fromReports;
  }
  get pathing(): Routes {
    return this.fromReports === 'true'
      ? Routes.REPORTS_DASHBOARD
      : Routes.STUDENT_DASHBOARD;
  }
  get naming(): string {
    return this.fromReports === 'true' ? 'Reports /' : '340th Dashboard /';
  }
  @Prop()
  readonly id!: number;
  startDate: string | null = '';
  graduationDate: string | null = '';
  className: string | null = '';
  searchService: TableSearchService<
    InProClassRosterTableData,
    BaseCandidate,
    never,
    SearchCandidateDto
  > | null = null;
  async created(): Promise<void> {
    this.setLocation();
    this.searchService = new TableSearchService<
      InProClassRosterTableData,
      BaseCandidate,
      never,
      SearchCandidateDto
    >(
      this.$store,
      new InProClassRosterDataProvider(this.$store),
      {
        inProId: this.id
      },
      { defaultSortProperty: 'identificationInformationLastname' },
      InProClassRosterTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
    await this.queryDatesAndName();
  }
  async queryDatesAndName(): Promise<void> {
    const query: SearchInProcessingClassDto = {
      id: this.id
    };
    const inProClass = await this.getOne({
      path: 'inProcessingClass',
      query,
      model: InProcessingClass
    });
    this.className = formatDate(inProClass.classStartDate) + ' Roster';
    if (inProClass.classEndDate) {
      const gradDate = formatDate(inProClass.classEndDate);
      const gradTime = formatTime(inProClass.classEndDate);
      this.graduationDate = `${gradDate} at ${gradTime}`;
    }
    if (inProClass.classStartDate) {
      const startDate = formatDate(inProClass.classStartDate);
      const startTime = formatTime(inProClass.classStartDate);
      this.startDate = `${startDate} at ${startTime}`;
    }
  }
}
